<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo" v-translate> Módulo de Descarga </h1>
      
      <v-row>
        <v-col cols="12">
          <v-row class="align-stretch">
            <v-col cols="12" md="9">
              <v-card>
                <v-card-text class="modal-cadastro-form pa-3 pb-1 pt-1" >
                  <v-row class="justify-space-between">
                    <v-col cols="12" md="5">
                      <autoCompleteEntities :hideDetails="true" :isOutlined="true" :entityProps.sync="entity" :typesEntities="['4']" />
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex flex-column align-end">
                      <div class="mb-2">
                        <strong>Valor total da Aposta: {{ totalGeral | currency }} </strong>
                      </div>

                      <div class="d-flex justify-space-between">
                        <v-btn @click="limpar" class="mr-2" :color="variables.colorSuccess" :loading="loadingAposta" dark>Limpar</v-btn>

                        <v-btn @click="registrar" :color="variables.colorPrimaryDarken" :loading="loadingAposta" dark>Registrar</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="3" class="order-first order-md-last">
              <v-card style="height: 100%;">
                <v-card-text class="modal-cadastro-form pa-3 pb-1 pt-1" style="height: 100%;">
                  <v-row>
                    <v-col cols="12" class="box-relogio">
                      Horário de Brasília <br />
                      <span>{{ `${hora}:${minuto}:${segundo}` }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-col>

        <template v-if="entity">
          <v-col cols="12" md="4">
            <v-card>
              <v-col class="pb-0">
                <p class="mb-0">
                  Loterias
                </p>
              </v-col>
              <v-card-text class="d-flex flex-wrap row pt-0 pr-0 pl-0 ma-0">
                <template v-for="(v, k) in loterias">
                  <v-checkbox
                    v-model="selectedLoterias"
                    :key="k"
                    :label="v.DS_LOT"
                    :value="v"
                    class="col-4 pa-1 caption checkbox-loterias mt-1"
                    :class="!v.show ? 'checkbox-loterias-disable' : ''"
                    :disabled="!v.show"
                    :error-messages="selectedLoteriasErrors"
                  ></v-checkbox>
                </template>
              </v-card-text>
            </v-card>

            <v-row class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loading">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              />
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <v-card-text class="d-flex flex-wrap row pa-0 ma-0">
                <v-col class="pb-0">
                  <v-autocomplete
                    v-model="selectedModalidade"
                    :items="modalidades"
                    outlined
                    label="Modalidades"
                    item-text="DS_MODAL"
                    item-value="ID_MODAL"
                    return-object
                    dense
                    :error-messages="selectedModalidadeErrors"
                  ></v-autocomplete>
                </v-col>
              </v-card-text>

              <template v-if="selectedModalidade.ID_MODAL">
                <v-col class="pb-0">
                  <p class="mb-0">Palpites</p>
                </v-col>
                <v-card-text class="d-flex flex-wrap row pa-0 ma-0">
                  <template v-for="(item, key, index) in palpites">
                    <v-col :key="index" cols="4" class="pb-0">
                      <v-text-field
                        dense
                        outlined
                        v-model="item.palpite"
                        :ref="index"
                        v-mask="maskNumbers()"
                        :error-messages="errorMensagens(key)"
                        @keyup="testPalpite(item, key, index, $event)"
                      />
                    </v-col>
                  </template>

                  <v-col cols="4" class="pb-0">
                    <v-btn
                      :color="variables.colorSuccess"
                      @click="addCampoPalpite()"
                      dark
                      class="mr-2"
                      :min-width="26"
                    >+</v-btn>

                    <v-btn
                      :color="variables.colorPrimaryDarken"
                      @click="removerCampoPalpite()"
                      dark
                      :min-width="26"
                    >-</v-btn>
                  </v-col>
                </v-card-text>
              </template>
              
              <v-col class="pb-0">
                <!-- <p class="mb-0">Prêmios</p> -->
              </v-col>

              <v-card-text class="d-flex flex-wrap row pa-0 ma-0">
                <v-col class="pb-0" md="6">
                  <v-autocomplete
                    v-model="selectedPremio"
                    :items="listPremios"
                    outlined
                    label="Prêmio"
                    return-object
                    dense
                    multiple
                    :error-messages="selectedPremioErrors"
                    class="auto-complete-premios"
                  ></v-autocomplete>
                </v-col>

                <v-col md="6">
                  <v-currency-field
                    v-model="valor"
                    label="Valor"
                    prefix="R$"
                    outlined
                    dense
                    :error-messages="valorErrors"
                  />
                </v-col>
              </v-card-text>

              <v-card-text class="d-flex flex-wrap row pa-0 ma-0">
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    :color="variables.colorSuccess"
                    @click.prevent="limparPalpites"
                    dark
                    class="mr-2"
                    :loading="loadingAposta"
                  >Limpar Palpites</v-btn>

                  <v-btn
                    :color="variables.colorPrimaryDarken"
                    @click.prevent="add"
                    dark
                    :loading="loadingAposta"
                  >Adicionar</v-btn>

                </v-col>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <v-col class="pb-0">
                <p class="mb-0">
                  Jogos
                </p>
              </v-col>
              <v-card-text class="d-flex flex-wrap row pa-0 ma-0 box-palpites">
                <template v-for="(game, key) in dadosDescarga">
                  <div :key="key" class="col-12 box-palpites-palpite">
                    <div class="d-flex justify-space-between">
                      <div>{{ `${game.modalidade.DS_MODAL} | ${stringPremios(game.premio)} | ${game.loteria.DS_LOT}` }} </div>
                      <div>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editItem(game, key)"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          small
                          @click="deleteItem(game)"
                        >
                          delete
                        </v-icon>
                      </div>

                    </div>
                    <div>{{ game.palpites.join(', ') }} </div>
                    <div>{{ game.valor | currency }}</div>
                  </div>
                </template>
              </v-card-text>

              <v-col>
                <p class="mb-0">
                  Total: <strong>{{ totalGeral | currency }}</strong>
                </p>
              </v-col>
              <!-- <v-card-text class="d-flex flex-wrap row pa-0 ma-0">
                <template v-for="(game, key) in dadosDescarga">
                  <div :key="key" class="col-12">
                    {{ `${game.modalidade.DS_MODAL} | ${game.premio} | ${game.loteria.DS_LOT}` }} <br />
                    {{ game.palpites.join(', ') }} <br />
                    {{ game.valor | currency }}
                  </div>
                </template>
              </v-card-text> -->
            </v-card>
          </v-col>
        </template>
      </v-row>
      
    </v-container>

    <modalTransmissao />
  </div>
</template>

<script>
// eslint-disable-next-line
import Events from '@/core/service/events'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
// eslint-disable-next-line
import { map, flatten, sortBy, size, filter, each, groupBy, orderBy, uniq, last, takeRight, remove  } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar, premiosFormat, currency } from '@/core/service/utils'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import moment from 'moment'
import { mask } from 'vue-the-mask'

import variables from '@/assets/styles/helpers/_variables.scss'

import validacao from './validacao'


const validatePalpite = modalidade => palpite => {
  console.log(palpite)
  let palpiteLimpo = palpite.palpite.replace(/[^0-9]/gi, "")
  if (palpiteLimpo.length === 0) return true

  if (palpiteLimpo.length > modalidade.QT_MAX || palpiteLimpo.length < modalidade.QT_MIN ) {
    return false
  }

  let isValid = true

  let arrPalpitesUniq = []

  if (!modalidade.RPT) {
    const arrPalpites = palpite.palpite.split('-')
    arrPalpitesUniq = uniq(arrPalpites)

    if (arrPalpites.length !== arrPalpitesUniq.length) return false
  }
  
  arrPalpitesUniq.forEach(item => {
    if (parseInt(item) > modalidade.QT_RANGE_MAX || parseInt(item) < modalidade.QT_RANGE_MIN) {
      isValid = false
    }
  })

  if (!isValid) {
    return false
  }

  return true
}

// const validateValueMin = (palpites, modalidades, loterias) => value => {
//   debugger
//   const total = parseFloat(value * size(palpites))
//   let calc = (total / size(palpites)) / size(loterias)

//   // if (calc > this.selectedModalidade.VL_LIM_AP) {
//   //   console.log('valor maximo atingido')
//   //   return false
//   // }

//   console.log(calc)

//   if (calc < 0.1) {
//     return {
//       type: false,
//       msg: 'valor minimo' + 0.01 * size(palpites)
//     }
//   }

//   return {
//     type: true
//   }
// }


export default {
  name: 'DescargaOnline',
  components: {
    autoCompleteEntities: () => import('../../components/autoCompleteEntities'),
    modalTransmissao: () => import('./components/modalTransmissao')
  },

  mixins: [validationMixin],

  directives: {
    mask
  },

  data: () => ({
    indexSelectGame: '',
    entity: null,
    selectedLoterias: [],
    selectedPremio: [],
    valor: 0,
    selectedModalidade: {},
    dadosDescarga: [],
    listPremios: [],
    hora: '',
    minuto: '',
    segundo: '',
    day: null,

    palpites: {
      palpite0: {
        palpite: ''
      }
    },

    idSetInterval: '',

    qtdItemForm: 1,
    setPalpite: '',
    loading: false,
    loadingAposta: false
  }),
  
  watch: {
    entity (val) {
      if (!val.value) return

      this.loading = true
      this.getDataInstallations({ entityId: val.value })
        .catch(() => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: `Instalação não encontrada para essa entidade: ${val.text}`
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => {
          this.loading = false
        })
    },

    palpites (val) {
      console.log(val)
      // debugger
    },

    selectedModalidade (val) {
      console.log(val)
      const filterLoteria = []
      this.loterias.forEach(item => {
       filterLoteria.push(...this.selectedLoterias.filter(subitem => subitem.ID_LOT === item.ID_LOT))
      })

      this.selectedLoterias = filterLoteria

      if (filterLoteria.length > 0) {

        const arrPremios =  [ ...val.CD_PREM ]
  
        this.listPremios = arrPremios.map(item => ({
            text: premiosFormat(item),
            value: item
        }))
      }

    }
  },
  
  mounted () {
    const self = this
    self.getDateServer().then(() => {
      self.idSetInterval = setInterval(() => self.timer(), 1000)
    })
  },

  computed: {
    ...mapGetters('descargaOnline', ['dataInstalations', 'dataPrognotico']),
    ...mapGetters('dateServer', ['getDate']),
    variables: () => variables,

    ...validacao,

    loterias () {
      if (this.dataPrognotico) {
        let data

        if (size(this.selectedModalidade)) {
          this.dataPrognotico.PROD.forEach(item => {
            item.MODAL.forEach(itemFilter => {
              if (itemFilter.ID_MODAL === this.selectedModalidade.ID_MODAL) {
                data = [ item.LOT ]
              }
            })
          })
          // const data = this.dataPrognotico.PROD.map(item => item.LOT)
        } else {
          data = this.dataPrognotico.PROD.map(item => item.LOT)
        }
        
        let arrayLoteria = []
  
        data.forEach(item => {
          item.forEach(subItem => {
            if (arrayLoteria.filter(itemFilter => itemFilter.DS_LOT === subItem.DS_LOT).length === 0) {
              let _dtSistema = moment(this.getDate).day()

              // remover loterias de acordo com o dia da semana
              if (parseInt(subItem.NR_DD_SORT.split('')[_dtSistema]) !== 0) {
                arrayLoteria.push(subItem)
              }
            }
          })
        })

        arrayLoteria.forEach(item => {
          item.show = parseInt(moment(this.getDate).format('Hmm')) >= item.DS_HS_LIM.replace(/:/g, '') ? false : true
        })
  
        return arrayLoteria
      }

      return []
    },

    modalidades () {
      if (this.dataPrognotico) {
        const arraySelectLoterias = this.dataPrognotico.PROD.map(item => item.MODAL)
      //   const arraySelectLoterias = []
        
      //   this.selectedLoterias.forEach(item => arraySelectLoterias.push(item.ID_LOT))

      //   let filterLoterias = this.dataPrognotico.PROD.filter(item => {
      //     const filterLoteria = item.LOT.filter(item => arraySelectLoterias.includes(item.ID_LOT))

      //     if (filterLoteria.length) return true
      //   })

      //   const filterLoteriasOrdernado = orderBy(filterLoterias, item => {
      //     return item.LOT
      //   }, 'asc')

      //   let modalidadesFiltradas = []
      //   if (filterLoteriasOrdernado.length) {
      //     const idLoterias = []
      //     filterLoteriasOrdernado[0].LOT.forEach(item => idLoterias.push(item.ID_LOT))
  
      //      filterLoteriasOrdernado.forEach(item => {
      //       const filterLoteria = item.LOT.filter(item => idLoterias.includes(item.ID_LOT))

      //       const arrayFilterLoteria = []
      //       filterLoteria.forEach(item => arrayFilterLoteria.push(item.ID_LOT))
  
      //       if (arrayFilterLoteria.length) {
      //         const loterias = filter(item.LOT, itemFilter => arrayFilterLoteria.includes(itemFilter.ID_LOT))
      //         if (loterias.length)
      //           modalidadesFiltradas.push(item.MODAL)
      //       }
      //     })
      //   }

        return flatten(arraySelectLoterias)
      }

      return []
    },

    totalGeral () {
      return this.dadosDescarga.reduce((acc, curr) => {
        return acc + parseFloat(curr.valor)
      }, 0)
    }
  },

  methods: {
    ...mapActions('descargaOnline', ['getDataInstallations', 'registrarDescargaOnline', 'clearDescargaOnline']),
    ...mapActions('dateServer', ['getDateServer', 'setDate']),

     init() {},

     convert (e) {
      const self = this
      const split = (self.selectedModalidade.QT_DIG_PALP === 1) ? self.selectedModalidade.QT_MAX : self.selectedModalidade.QT_DIG_PALP 
      const n = String(e).match(new RegExp('.{' + split + '}', 'g'))
      let valueFormatado = n
      if (self.selectedModalidade.IN_PALP_ORD === 1) {
        valueFormatado = sortBy(valueFormatado)
      }
      return (valueFormatado) ? valueFormatado.join('-') : String(e)
      // return (n) ? n.join('-') : String(e)
    },

     maskNumbers () {
      let self = this
      let str = String('#').repeat(self.selectedModalidade.QT_MAX)
      if (str) return self.convert(str)
      return ''
    },

    addItem (palpite = '') {
      this.qtdItemForm++
      this.$set(this.palpites, `palpite${this.qtdItemForm}`, {
        palpite
      })
    },

    validarNumber (item) {
      const arrNumber = item.palpite.split('-')
      arrNumber.forEach(item => item = item.padStart(this.selectedModalidade.QT_DIG_PALP, '0'))
      if (!this.selectedModalidade.RPT) item.palpite = uniq(arrNumber).join('-')

      const lastNumber = last(arrNumber)
      if (parseInt(lastNumber) > this.selectedModalidade.QT_RANGE_MAX) {
        item.palpite = arrNumber.filter(it => parseInt(it) !== parseInt(lastNumber)).join('-')
      }

      if (String(lastNumber).length >= 2 && parseInt(lastNumber) < this.selectedModalidade.QT_RANGE_MIN) {
        item.palpite = arrNumber.filter(it => parseInt(it) !== parseInt(lastNumber)).join('-')
      }
    },

    testPalpite (item, key, index) {
      this.validarNumber(item)

      if (this.selectedModalidade.CD_PREM.filter(item => String(item).length > 1)) {
        if (item.palpite.replace(/[^0-9]/gi, "").length  >= this.selectedModalidade.QT_RANGE_MAX) {
          if (this.setPalpite === key) return

          this.$v.palpites[key].$touch()

          this.setPalpite = key
          this.addItem()
          const self = this
          setTimeout(() => {
            self.$refs[index + 1][0].focus()
          }, 100)

        } else if (!item.palpite.replace(/[^0-9]/gi, "").length && size(this.palpites) > 1) {
          this.$delete(this.palpites, key)
          this.setPalpite = ''
          const self = this
          setTimeout(() => {
            self.$refs[index - 1][0].focus()
          }, 100)
        }
      }
    },

    removerCampoPalpite () {
      if (size(this.palpites) > 1) {
        const lastCampoPalpite = takeRight(map(this.palpites, (item, key) => key))
        this.$delete(this.palpites, lastCampoPalpite[0])
      }
    },

    addCampoPalpite () {
      this.addItem()
    },

    timer () {
      this.day = moment(this.getDate).toDate()
      this.hora = String(this.day.getHours()).padStart(2, '0')
      this.minuto = String(this.day.getMinutes()).padStart(2, '0')
      this.segundo = String(this.day.getSeconds()).padStart(2, '0')

      this.setDate(moment(this.day).add(1, 's').toDate())
      // this.day = moment(this.day).add(1, 's').toDate()
    },

    add () {
      // this.palpites = filter(this.palpites, item => item.palpite)
      const self = this
      if(this.$v.$invalid) {
        console.log('invalido')
        this.$v.$touch()
        return
      }

      each(this.palpites, (item, key) => {
        const arrayPalpite = item.palpite.split('-')
        each(arrayPalpite, (it, key) => arrayPalpite[key] = it.padStart(self.selectedModalidade.QT_DIG_PALP, '0'))
        item.palpite = arrayPalpite.join('-')
        // se string  tiver só '0' elimina do array
        let format = item.palpite.replace(/[0]/g, '')
        if (size(format) === 0) {
          this.$delete(this.palpites, key)
        }
      })

      const total = parseFloat(this.valor * size(this.palpites))

      let calc = (total / size(this.palpites)) / size(this.selectedLoterias)
      
      let valorMaximoAposta = this.selectedModalidade.VL_LIM_AP * Math.max(size(this.palpites), size(this.selectedLoterias))

      if (Number.isFinite(calc) && calc > this.selectedModalidade.VL_LIM_AP) {
        this.$swal({
          icon: 'error',
          text: `O valor máximo permitido para cada palpite e loteria apostado é de ${currency(this.selectedModalidade.VL_LIM_AP)}. Valor máximo sugerido para sua aposta é de ${currency(valorMaximoAposta)}`,
          showConfirmButton: false,
        })
        return false
      }

      const format = this.selectedLoterias.map(it => ({
        dataAposta: moment().format('YYMMDD'),
        loteria: it,
        modalidade: this.selectedModalidade,
        premio: this.selectedPremio,
        palpites: map(this.palpites, item => item.palpite),
        valor: total
      }))

      if (typeof this.indexSelectGame === 'number' && isFinite(this.indexSelectGame) && this.indexSelectGame >= 0) {
        this.dadosDescarga.splice(this.indexSelectGame, 1)
        this.indexSelectGame = ''
      }

      this.dadosDescarga.push(...format)
      this.limparDadosDescarga()

      this.setPalpite = ''
      this.$v.$reset()
    },

    limpar () {
      this.clearDescargaOnline()
      this.entity = {},
      this.selectedLoterias = [],
      this.selectedPremio = [],
      this.valor = '',
      this.selectedModalidade = {},
      this.dadosDescarga = [],

      this.palpites = {
        palpite0: {
          palpite: ''
        }
      }
    },

    limparPalpites () {
      this.palpites = {
        palpite0: {
          palpite: ''
        }
      }
    },

    limparDadosDescarga () {
      this.selectedLoterias = [],
      this.selectedPremio = [],
      this.valor = '',
      this.selectedModalidade = {},

      this.palpites = {
        palpite0: {
          palpite: ''
        }
      }
    },

    registrar () {
      let groupted = groupBy(this.dadosDescarga, item => {
        return item.loteria.ID_LOT
      })

      const data = {
        D: moment(this.getDate).format('YYMMDD'),
        H: '',
        T: this.totalGeral,
        U: this.entity.value,
        I: this.gerarCodigoBilheteUnico(),
        B: map(groupted, (item) => ({
            A: moment(this.getDate).format('YYMMDD'),
            L: [item[0].loteria.ID_LOT],
            P: map(item, game => ({
                M: game.modalidade.ID_MODAL,
                R: 1,
                E: this.getPremiosIds(game.premio),
                J: ((palpites) => {
                  let palpitesOrdernado = palpites
                  if (game.modalidade.IN_PALP_ORD) {
                    palpitesOrdernado = map(palpites, palpite => {
                      return sortBy(String(palpite).split('-'))
                    })
                  }

                  return map(palpitesOrdernado, palpite => String(palpite).replace(/-/g, '').replace(/,/g, ''))
                })(game.palpites),
                V: game.valor
            })),
        })),

        NR_SERIAL: this.dataInstalations.device.serial
      }

      this.loading = true
      this.loadingAposta = true
      this.registrarDescargaOnline(data)
        .then(result => {
          Events.$emit('modal::descargaOnline::transmissao', { ...result.data, entity: this.entity })
          this.limpar()
        })
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => {
          this.loading = false
          this.loadingAposta = false
        })
    },

    gerarCodigoBilheteUnico() {
        const lista = "0123456789BCDFGHJKLMNPQRSTVWXYZ".split('')
        const customEpoch = moment('2018-01-01 00:00:00').format('x')
        const now = moment().format('x')
        let t_diferenca = (now - customEpoch)/1000
        let retorno = ""
        while(t_diferenca > lista.length - 1) {
            let resto = t_diferenca % lista.length
            t_diferenca = (t_diferenca - resto) / lista.length
            retorno += lista[parseInt(resto)]
        }
        retorno += lista[parseInt(t_diferenca)]

        return retorno.split('').reverse().join('')
    },

    errorMensagens (index) {
      if (!index || this.palpites[index].palpite.length <= 0) return
      let error = []
      !this.$v.palpites[index].isValidPalpites && error.push('Palpite inválido')
      return error
    },

    editItem (item, index) {
      this.indexSelectGame = index
      this.selectedLoterias = [item.loteria],
      this.selectedPremio = item.premio,
      this.valor = parseFloat(item.valor) / size(item.palpites),
      this.selectedModalidade = item.modalidade

      this.palpites = {}
      item.palpites.forEach(subItem => {
        this.addItem(subItem)
      })
    },

    deleteItem (index) {
      this.dadosDescarga.splice(index, 1)
    },

    stringPremios (premios) {
      const arrPremios = [ ...premios.map(premio => premio.text) ]
      return arrPremios.join(', ')
    },

    getPremiosIds (premios) {
      const arrPremios = [ ...premios.map(premio => premio.value) ]
      return arrPremios
    }
  },

  beforeDestroy () {
    clearInterval(this.idSetInterval)
  },

  validations () {
    const vm = this
    const palpites = {}

    each(vm.palpites, (v, k) => {
      palpites[k] = {
        isValidPalpites: validatePalpite(vm.selectedModalidade)
      }
    })

    const selectedModalidade = {
      required
    }
    
    const selectedPremio = {
      required
    }
    
    const selectedLoterias = {
      required
    }
    
    const valor = { required, minValue: minValue(0.01) }


    // return args.item = { required, minLenght: minLenght(14)}
    return  { palpites,  selectedModalidade, selectedPremio, selectedLoterias, valor }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/modal-cadastro';
  .box-relogio {
    text-align: center;
    font-size: 20px;
    line-height: 1.1;

    span {
      font-size: 40px;
      font-weight: bold;
    }
  }

  .box-palpites {
    &-palpite {
      background-color: rgba(0,0,0,.09);
      border-top: 1px dashed rgba(0, 0, 0, .2);

      &:nth-child(2n) {
        background-color: rgba(0,0,0,.03);
      }

      &:first-child {
        margin-top: 10px !important;
      }

      &:last-child {
        border-bottom: 1px dashed rgba(0, 0, 0, .2);
      }
    }
  }

  .checkbox-loterias-disable {
    color:rgba(0, 0, 0, .2) !important;
  }

  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 10px;
  }
</style>
