import { REQUIRED_FIELD } from '@/utils/validationMessagesUtils'

export default {
  selectedModalidadeErrors () {
    const errors = []
    if (!this.$v.selectedModalidade.$dirty) return errors
    !this.$v.selectedModalidade.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  selectedPremioErrors () {
    const errors = []
    if (!this.$v.selectedPremio.$dirty) return errors
    !this.$v.selectedPremio.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  selectedLoteriasErrors () {
    const errors = []
    if (!this.$v.selectedLoterias.$dirty) return errors
    !this.$v.selectedLoterias.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  valorErrors () {
    const errors = []
    if (!this.$v.valor.$dirty) return errors
    !this.$v.valor.required && errors.push(REQUIRED_FIELD)
    !this.$v.valor.minValue && errors.push('Valor minimo é R$ 0,01')
    return errors
  }
}